import { useEffect, useState } from "react";

import playlistIcon from "../../assets/images/listen/playlist.svg";
import Loader from "../../components/ui/Loader";
import useStore from "../../hooks/store/useStore";
import useQueryCustom from "../../services/utils/useQueryCustom";
import useQueryLrc from "../../services/utils/useQueryLrc";
import cn from "../../utils/cn";
import BookButton from "../bookDemo/BookButton";
import { PaddingForBookButtonWeb } from "../bookDemo/constants";

import AudioPlayer from "./audioPlayer/AudioPlayer";
import AppDownloadModel from "./components/AppDownloadModel";
import PlayList from "./components/PlayList";
import Tag from "./components/Tag";
import UnlockModel from "./components/UnlockModel";
import Lyrics from "./lyrics/Lyrics";
import { bgColorMapping } from "./constants";
import { SongDataType } from "./types";

export default function Listen() {
  const [songs, setSongs] = useState<SongDataType[]>([]);
  const [unlockedSongs, setUnlockedSongs] = useState<SongDataType[]>([]);
  const [unlockedSongIndex, setUnlockedSongIndex] = useState(0);
  const [showLyrics, setShowLyrics] = useState(false);
  const [currentSong, setCurrentSong] = useState<SongDataType | null>(null);
  const [timeProgress, setTimeProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [repeat, setRepeat] = useState(false);
  const [currentLyricIndex, setCurrentLyricIndex] = useState(-1);

  /**
   * CLUBAPP-5525
   * When switching devices or changing windows, there may be unknown bugs
   * that cause certain audio-related setState functions to revert back to
   * their original state after about 0.2 seconds of execution.
   */
  const [unknown, setUnknown] = useState(true);

  const { showWannaUnlockModel } = useStore((state) => state.bookDemo);
  const { showAppDownloadModel } = useStore((state) => state.webVersion);
  const { source } = useStore((state) => state.global);

  const { data, isLoading, isError } = useQueryCustom(
    "useSongList",
    "song-list",
  );
  const {
    data: lyricsData,
    isLoading: isLyricsLoading,
    isError: isLyricsError,
  } = useQueryLrc(
    currentSong?.songTitle,
    currentSong?.audio.lyricsUrl,
    Boolean(currentSong),
  );

  useEffect(() => {
    if (data && data.length > 0) {
      setSongs(data);
      setUnlockedSongs(data.filter((s: SongDataType) => !s.locked));
    }
  }, [data, setSongs]);

  useEffect(() => {
    if (unlockedSongs && unlockedSongs.length > 0) {
      setCurrentSong(unlockedSongs[0]);
    }
  }, [unlockedSongs, setCurrentSong]);

  if (isLoading || !currentSong) return <Loader page="listen" />;
  if (isError) return <p>Error</p>;

  const currentSongIndex = songs.findIndex(
    (song) => song.songId === currentSong.songId,
  );
  const bgColor = bgColorMapping[songs[currentSongIndex].categoryName];
  const paddingBottom = source === "web" ? PaddingForBookButtonWeb : "";

  return (
    <>
      <AudioPlayer
        key={currentSong.songId}
        songs={unlockedSongs}
        showLyrics={showLyrics}
        setShowLyrics={setShowLyrics}
        currentSong={currentSong}
        setCurrentSong={setCurrentSong}
        songIndex={unlockedSongIndex}
        setSongIndex={setUnlockedSongIndex}
        timeProgress={timeProgress}
        setTimeProgress={setTimeProgress}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        repeat={repeat}
        setRepeat={setRepeat}
        setCurrentLyricIndex={setCurrentLyricIndex}
        unknown={unknown}
        setUnknown={setUnknown}
      />

      <div className={cn("flex-1 bg-blue px-8", bgColor, paddingBottom)}>
        {showLyrics ? (
          <div>
            <div className="flex flex-row-reverse py-4">
              <Tag
                text="playlist"
                className="bg-white bg-opacity-20"
                textStyle="text-white"
                icon={playlistIcon}
                onClick={() => setShowLyrics(false)}
              />
            </div>

            <Lyrics
              lrc={lyricsData || ""}
              currentMillisecond={timeProgress}
              isLoading={isLyricsLoading}
              isError={isLyricsError}
              currentLyricIndex={currentLyricIndex}
              setCurrentLyricIndex={setCurrentLyricIndex}
            />
          </div>
        ) : (
          <PlayList
            songs={songs}
            unlockedSongs={unlockedSongs}
            currentSongIndex={currentSongIndex}
            setCurrentSong={setCurrentSong}
            setUnlockedSongIndex={setUnlockedSongIndex}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            unknown={unknown}
            setUnknown={setUnknown}
          />
        )}
      </div>

      {showWannaUnlockModel ? <UnlockModel /> : null}
      {showAppDownloadModel ? (
        <AppDownloadModel eventId="Repeat Button" />
      ) : null}

      <BookButton sourcePage="Listen" />
    </>
  );
}
