import { Dispatch, SetStateAction } from "react";

import dweLogo from "../../assets/images/webLogin/dweLogo.svg";
import bubble1 from "../../assets/images/menu/disneyWeb/bubble1.svg";
import bubble2 from "../../assets/images/menu/disneyWeb/bubble2.png";
import bubble3 from "../../assets/images/menu/disneyWeb/bubble3.svg";
import useStore from "../../hooks/store/useStore";
import { MenuItemType } from "../../types";
import BookButton from "../bookDemo/BookButton";
import AppDownloadModel from "../listen/components/AppDownloadModel";
import MenuItem from "./MenuItem";
import ClubAppMenuItem from "./ClubAppMenuItem";

export default function DisneyMenuWeb({
  setSelectedMenuItem,
  setShowDisneyMenu,
}: {
  setSelectedMenuItem: Dispatch<SetStateAction<MenuItemType>>;
  setShowDisneyMenu: Dispatch<SetStateAction<boolean | null>>;
}) {
  const { showAppDownloadModel } = useStore((state) => state.webVersion);

  return (
    <div className="relative flex min-h-screen w-full flex-col items-center justify-start bg-gradient-to-b from-white to-pink1">
      <img src={dweLogo} alt="dweLogo" className="mb-9 mt-4 w-[162px]" />

      <div className="relative flex w-screen min-w-[320px] max-w-[480px] flex-col">
        <div className="relative mb-6 w-full">
          <MenuItem
            name="donald"
            className="ml-[72px]"
            setSelectedMenuItem={setSelectedMenuItem}
            setShowDisneyMenu={setShowDisneyMenu}
            isPink
          />
          <MenuItem
            name="minnie"
            className="mr-5"
            setSelectedMenuItem={setSelectedMenuItem}
            setShowDisneyMenu={setShowDisneyMenu}
            isPink
          />
        </div>

        <div className="relative h-[200px] w-full">
          <MenuItem
            name="mickey"
            className="ml-6"
            setSelectedMenuItem={setSelectedMenuItem}
            setShowDisneyMenu={setShowDisneyMenu}
            isPink
          />
          <MenuItem
            name="goofy"
            className="mr-8"
            setSelectedMenuItem={setSelectedMenuItem}
            setShowDisneyMenu={setShowDisneyMenu}
            isPink
          />
        </div>

        <div className="relative h-fit w-full">
          <ClubAppMenuItem />
        </div>

        <img
          src={bubble1}
          alt="bubble1"
          className="floating absolute right-4 top-12 size-10"
        />
        <img
          src={bubble2}
          alt="bubble2"
          className="floating absolute left-4 top-[164px] size-6"
          style={{ animationDelay: "0.7s" }}
        />
        <img
          src={bubble3}
          alt="bubble3"
          className="floating absolute right-10 top-[474px] size-6"
          style={{ animationDelay: "1.3s" }}
        />
      </div>

      {showAppDownloadModel ? (
        <AppDownloadModel eventId="World Family Club" />
      ) : null}

      <BookButton sourcePage="Disney Menu" />
    </div>
  );
}
