import QA34Q1 from "../../assets/audios/qaCards/QA34Q1.mp3";
import QA34Q1CA from "../../assets/audios/qaCards/QA34Q1CA.mp3";
import QA34Q2 from "../../assets/audios/qaCards/QA34Q2.mp3";
import QA34Q2CA from "../../assets/audios/qaCards/QA34Q2CA.mp3";
import QA34a from "../../assets/audios/qaCards/QA34a.mp3";
import QA34b from "../../assets/audios/qaCards/QA34b.mp3";
import QA34c from "../../assets/audios/qaCards/QA34c.mp3";
import QA34d from "../../assets/audios/qaCards/QA34d.mp3";
import QA34e from "../../assets/audios/qaCards/QA34e.mp3";

import QA39Q1 from "../../assets/audios/qaCards/QA39Q1.mp3";
import QA39Q1CA from "../../assets/audios/qaCards/QA39Q1CA.mp3";
import QA39Q2 from "../../assets/audios/qaCards/QA39Q2.mp3";
import QA39Q2CA from "../../assets/audios/qaCards/QA39Q2CA.mp3";
import QA39b from "../../assets/audios/qaCards/QA39b.mp3";
import QA39c from "../../assets/audios/qaCards/QA39c.mp3";
import QA39d from "../../assets/audios/qaCards/QA39d.mp3";

import QA32Q1 from "../../assets/audios/qaCards/QA32Q1.mp3";
import QA32Q1CA from "../../assets/audios/qaCards/QA32Q1CA.mp3";
import QA32Q2 from "../../assets/audios/qaCards/QA32Q2.mp3";
import QA32Q2CA from "../../assets/audios/qaCards/QA32Q2CA.mp3";
import QA32a from "../../assets/audios/qaCards/QA32a.mp3";
import QA32b from "../../assets/audios/qaCards/QA32b.mp3";
import QA32c from "../../assets/audios/qaCards/QA32c.mp3";
import QA32d from "../../assets/audios/qaCards/QA32d.mp3";
import QA32e from "../../assets/audios/qaCards/QA32e.mp3";

import f34 from "../../assets/images/qaCards/f34.png";
import f39 from "../../assets/images/qaCards/f39.png";
import f32 from "../../assets/images/qaCards/f32.png";
import b34 from "../../assets/images/qaCards/b34.png";
import b39 from "../../assets/images/qaCards/b39.png";
import b32 from "../../assets/images/qaCards/b32.png";

import { QACardType } from "../../types";
import { QACardAudioDataType } from "./types";

export const LandingCardPanelDuration = 1200;
export const LandingPlaymateDuration = 800;
export const FlipCardDuration = 1000;
export const CenterCardDuration = 1000;
export const RotateCardDuration = 1000;

export const qacAudioData: {
  [key in QACardType]: QACardAudioDataType;
} = {
  qa34: {
    frontAudio: [
      { audio: QA34Q1, duration: 2000, text: "Who wants to go fishing?" },
      { audio: QA34Q2, duration: 2000, text: "Who wants to go swimming?" },
    ],
    backAudio: (frontIndex) => [
      frontIndex === 0
        ? {
            audio: QA34Q1CA,
            duration: 5000,
            text: "I do! That's right! Donald wants to go fishing.",
          }
        : { audio: QA34a, duration: 3000, text: "Donald wants to go fishing." },
      frontIndex === 1
        ? {
            audio: QA34Q2CA,
            duration: 3800,
            text: "Oh, yes! I want to go swimming!",
          }
        : {
            audio: QA34b,
            duration: 3000,
            text: "Clarabelle wants to go swimming.",
          },
      { audio: QA34c, duration: 2000, text: "Mickey wants to go hiking." },
      {
        audio: QA34d,
        duration: 6000,
        text: "Goofy is very hungry. He wants to eat a lot of food.",
      },
      {
        audio: QA34e,
        duration: 4000,
        text: "Huey, Dewey, and Louie want to play baseball.",
      },
    ],
  },
  qa39: {
    frontAudio: [
      {
        audio: QA39Q1,
        duration: 2000,
        text: "Who's drinking a glass of milk?",
      },
      { audio: QA39Q2, duration: 3000, text: "Who's going to eat a hot dog?" },
    ],
    backAudio: (frontIndex) => [
      frontIndex === 0
        ? {
            audio: QA39Q1CA,
            duration: 6000,
            text: "Yep, it's me. That's right! Donald is drinking a glass of milk.",
          }
        : {
            audio: QA39Q2CA,
            duration: 5000,
            text: "Me! That's right! Donald's going to eat a hot dog.",
          },
      {
        audio: QA39b,
        duration: 9000,
        text: "Goofy is eating a piece of chicken right now. Then he's going to drink a glass of water.",
      },
      {
        audio: QA39c,
        duration: 2000,
        text: "Daisy is cutting up the chicken.",
      },
      {
        audio: QA39d,
        duration: 7000,
        text: "Minnie is pouring lemonade into a glass.",
      },
    ],
  },
  qa32: {
    frontAudio: [
      {
        audio: QA32Q1,
        duration: 3000,
        text: "Who's putting the picnic basket in the car?",
      },
      {
        audio: QA32Q2,
        duration: 3000,
        text: "Who's putting a blanket in the trunk?",
      },
    ],
    backAudio: (frontIndex) => [
      frontIndex === 0
        ? {
            audio: QA32Q1CA,
            duration: 6800,
            text: "Uh-huh, I am! That's right! Minnie's putting the picnic basket in the car.",
          }
        : {
            audio: QA32a,
            duration: 7000,
            text: "Minnie is putting the picnic basket in the car. The basket is full of food for the picnic.",
          },
      frontIndex === 1
        ? {
            audio: QA32Q2CA,
            duration: 7000,
            text: "I am! That's right! Clarabelle's putting a blanket in the trunk.",
          }
        : {
            audio: QA32b,
            duration: 4000,
            text: "Clarabelle is putting a blanket in the trunk.",
          },
      {
        audio: QA32c,
        duration: 6000,
        text: "Goofy is holding some towels. He's going to put them in the trunk.",
      },
      {
        audio: QA32d,
        duration: 6000,
        text: "Pluto is holding a bucket. He's going to put the bucket in the car.",
      },
      {
        audio: QA32e,
        duration: 7000,
        text: "Mickey is already in the car. He's going to drive everyone to the picnic.",
      },
    ],
  },
};

export const tacImageData: {
  [key in QACardType]: {
    frontImage: string;
    backImage: string;
  };
} = {
  qa34: {
    frontImage: f34,
    backImage: b34,
  },
  qa39: {
    frontImage: f39,
    backImage: b39,
  },
  qa32: {
    frontImage: f32,
    backImage: b32,
  },
};
