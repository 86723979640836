import { useEffect, useState } from "react";

import labelSa from "../../assets/images/watch/label-sa.png";
import labelPa from "../../assets/images/watch/label-pa.png";
import Loader from "../../components/ui/Loader";
import useStore from "../../hooks/store/useStore";
import useQueryCustom from "../../services/utils/useQueryCustom";
import cn from "../../utils/cn";
import BookButton from "../bookDemo/BookButton";
import { PaddingForBookButtonWeb } from "../bookDemo/constants";
import Label from "./components/Label";
import VideoList from "./components/VideoList";
import MainCard from "./components/MainCard";
import { VideoDataType } from "./types";
import convertToVideoDataWithVimeoUrl from "./utils/convertToVideoDataWithVimeoUrl";

export default function Watch() {
  const [firstVideo, setFirstVideo] = useState<VideoDataType | null>(null);
  const [currentVideo, setCurrentVideo] = useState<VideoDataType | null>(null);
  const [tbcVideoData, setTbcVideoData] = useState<VideoDataType[] | null>(
    null,
  );
  const [paVideoData, setPaVideoData] = useState<VideoDataType[] | null>(null);
  const [saVideoData, setSaVideoData] = useState<VideoDataType[] | null>(null);

  const { source } = useStore((state) => state.global);

  const {
    data: tbcData,
    isLoading: loadingTbc,
    isError: errorTbc,
  } = useQueryCustom("useTBCVideoList", "TBC/video-list");
  const {
    data: paData,
    isLoading: loadingPa,
    isError: errorPa,
  } = useQueryCustom("usePAVideoList", "PA/video-list");
  const {
    data: saData,
    isLoading: loadingSa,
    isError: errorSa,
  } = useQueryCustom("useSAVideoList", "SA/video-list");

  useEffect(() => {
    if (paData && paData.length > 0) {
      const videoData = convertToVideoDataWithVimeoUrl(paData);
      const firstVideo =
        videoData.find((video: VideoDataType) => !video.locked) || null;

      setPaVideoData(videoData);
      setFirstVideo(firstVideo);
    }
  }, [paData, setFirstVideo]);

  useEffect(() => {
    if (tbcData && tbcData.length > 0)
      setTbcVideoData(convertToVideoDataWithVimeoUrl(tbcData));
  }, [tbcData]);

  useEffect(() => {
    if (saData && saData.length > 0)
      setSaVideoData(convertToVideoDataWithVimeoUrl(saData));
  }, [saData]);

  if (
    loadingTbc ||
    loadingPa ||
    loadingSa ||
    !firstVideo ||
    !tbcVideoData ||
    !paVideoData ||
    !saVideoData
  )
    return <Loader page="watch" />;
  if (errorTbc || errorPa || errorSa) return <p>Error</p>;

  const paddingBottom = source === "web" ? PaddingForBookButtonWeb : "";

  return (
    <div className={cn("w-full p-5", paddingBottom)}>
      <MainCard firstVideo={firstVideo} currentVideo={currentVideo} />

      <VideoList
        listType="tbc"
        data={tbcVideoData}
        setCurrentVideo={setCurrentVideo}
      />

      <Label text="Play Along!" url={labelPa} />

      <VideoList
        listType="pa"
        data={paVideoData}
        setCurrentVideo={setCurrentVideo}
      />

      <Label text="Sing Along!" url={labelSa} />

      <VideoList
        listType="sa"
        data={saVideoData}
        setCurrentVideo={setCurrentVideo}
      />

      <BookButton sourcePage="Watch" />
    </div>
  );
}
