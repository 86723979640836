import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DndContext } from "@dnd-kit/core";

import playmate from "../../assets/images/gameShared/playmate.png";
import reverse from "../../assets/images/gameShared/reverse.svg";
import orangeBg from "../../assets/images/talkAlongCards/orangeBg.png";
import eatFront from "../../assets/images/talkAlongCards/eatFront.png";
import playFront from "../../assets/images/talkAlongCards/playFront.png";
import picnicFront from "../../assets/images/talkAlongCards/picnicFront.png";
import replay from "../../assets/images/talkAlongCards/replay.png";

import GameTitle from "../../components/game/GameTitle";
import GameButton from "../../components/game/GameButton";
import Draggable from "../../components/game/Draggable";
import Droppable from "../../components/game/Droppable";
import Text from "../../components/ui/Text";
import Mask from "../../components/ui/Mask";
import useCtaStore from "../../hooks/store/useCtaStore";
import useStore from "../../hooks/store/useStore";
import useEventTracking from "../../services/EventTracking/useEventTracking";
import ctaHandler6 from "../../utils/ctaHandlers/ctaHandler6";
import cn from "../../utils/cn";
import { TACardType } from "../../types";

import CardHandler from "./components/CardHandler";
import TACDialog from "./components/TACDialog";
import HelpModel from "./components/HelpModel";
import { isWaiting } from "./utils/judgements";
import { FlipCardDuration, tacAudioData } from "./constants";
import { TACGameState } from "./types";

export default function TalkAlongCards() {
  const { t } = useTranslation();

  const eatFRef = useRef<HTMLAudioElement>(null);
  const eatB0Ref = useRef<HTMLAudioElement>(null);
  const eatB1Ref = useRef<HTMLAudioElement>(null);
  const playFRef = useRef<HTMLAudioElement>(null);
  const playB0Ref = useRef<HTMLAudioElement>(null);
  const playB1Ref = useRef<HTMLAudioElement>(null);
  const picnicFRef = useRef<HTMLAudioElement>(null);
  const picnicB0Ref = useRef<HTMLAudioElement>(null);
  const picnicB1Ref = useRef<HTMLAudioElement>(null);

  const refMapping = {
    eat: {
      front: eatFRef,
      back: [eatB0Ref, eatB1Ref],
    },
    play: {
      front: playFRef,
      back: [playB0Ref, playB1Ref],
    },
    picnic: {
      front: picnicFRef,
      back: [picnicB0Ref, picnicB1Ref],
    },
  };

  const [gameState, setGameState] =
    useState<TACGameState>("waitingForDragging");
  const [currentCard, setCurrentCard] = useState<TACardType | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [backAudioIndex, setBackAudioIndex] = useState(0);
  const [showHelp, setShowHelp] = useState(false);
  const [isFirstPlay, setIsFirstPlay] = useState(true);

  const { userInformation } = useStore((state) => state.user);
  const { setShowingCtaETId } = useCtaStore((state) => state.cta);

  const mutation = useEventTracking();

  const droppableStyle = isDragging ? "blue-frame" : "";
  const isWaitingPhase = isWaiting(gameState);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    const activeId = active.id as TACardType;

    setIsDragging(false);

    if (over && over.id === "droppable") {
      setCurrentCard(activeId);
      setBackAudioIndex(0);
      setGameState("frontPlaying");

      const audioRef = refMapping[activeId].front;
      audioRef.current?.play();
    }
  };

  const handleflip = () => {
    setGameState("cardFlippingAndReset");

    setTimeout(() => {
      setGameState("backPlaying");

      if (!currentCard) return;

      const audioRef = refMapping[currentCard].back[backAudioIndex];
      audioRef.current?.play();
    }, FlipCardDuration);
  };

  const handleReplay = () => {
    if (!currentCard) return;

    const nextIndex = backAudioIndex === 0 ? backAudioIndex + 1 : 1;

    if (backAudioIndex === 0) setBackAudioIndex(nextIndex);

    setGameState("cardReset");

    setTimeout(() => {
      setGameState("backPlaying");

      if (!currentCard) return;

      const audioRef = refMapping[currentCard].back[nextIndex];
      audioRef.current?.play();
    }, FlipCardDuration);
  };

  const handleAudioFrontEnded = () => {
    setGameState("waitingForFlipping");

    setTimeout(() => {
      if (isFirstPlay) {
        setIsFirstPlay(false);
      } else {
        ctaHandler6(userInformation, setShowingCtaETId);
      }
    }, 150);

    // Written here to prevent animations from being disrupted by POST requests
    if (currentCard)
      setTimeout(
        () =>
          mutation.mutate({
            pageId: "Talk Along Cards",
            eventId: currentCard,
          }),
        450,
      );
  };

  const handleAudioBackEnded = () => setGameState("waitingForReplay");

  useEffect(() => {
    setShowHelp(true);
  }, []);

  return (
    <>
      <GameTitle
        text="Talk Along Cards"
        onHelpClick={() => {
          if (isWaitingPhase) setShowHelp(true);
        }}
      />

      {showHelp ? <HelpModel setShowHelp={setShowHelp} /> : null}

      {gameState === "frontPlaying" ? <Mask /> : null}

      <div className="relative flex h-full flex-1 flex-col bg-pink1">
        {gameState === "waitingForDragging" ? (
          <div className="relative z-50 mb-6 px-6">
            <Text normal>{t("talkAlongCardsDesc")}</Text>
          </div>
        ) : (
          <TACDialog
            gameState={gameState}
            currentCard={currentCard}
            backAudioIndex={backAudioIndex}
          />
        )}

        <DndContext
          onDragEnd={handleDragEnd}
          onDragStart={() => {
            setIsDragging(true);
          }}
        >
          <div className="absolute left-0 top-0 mt-16 w-screen max-w-[390px]">
            <div className="centered-child h-full w-screen">
              <img
                src={orangeBg}
                alt="orange bg"
                className="max-h-[408px] w-full"
              />
            </div>

            <div className="absolute left-0 top-[207px] flex w-screen max-w-[390px] flex-col items-center">
              <div className="flex w-full flex-col items-center justify-center">
                <img
                  src={playmate}
                  alt="playmate"
                  className="relative z-50 w-[255px]"
                />

                <CardHandler
                  gameState={gameState}
                  currentCard={currentCard}
                  backAudioIndex={backAudioIndex}
                />

                <Droppable
                  className={cn(
                    droppableStyle,
                    "absolute -top-[96px] right-3 z-20 h-[122px] w-[270px]",
                  )}
                />

                {gameState === "waitingForFlipping" ? (
                  <GameButton
                    blue
                    image={reverse}
                    text={t("flipCard")}
                    onClick={handleflip}
                    className="absolute top-[100px]"
                  />
                ) : null}

                {gameState === "waitingForReplay" ? (
                  <GameButton
                    image={replay}
                    text={t("playAgain")}
                    onClick={handleReplay}
                    className="absolute top-[100px]"
                  />
                ) : null}

                <div className="mt-[112px] flex flex-col items-center">
                  {currentCard !== "picnic" ? (
                    <Draggable id="picnic" isDraggable={isWaitingPhase}>
                      <img
                        src={picnicFront}
                        alt="picnicFront"
                        className="relative z-10 w-[133px]"
                      />
                    </Draggable>
                  ) : (
                    <div className="relative z-0 h-[60px] w-40" />
                  )}
                  {currentCard !== "play" ? (
                    <Draggable id="play" isDraggable={isWaitingPhase}>
                      <img
                        src={playFront}
                        alt="playFront"
                        className="relative -top-5 z-20 w-40"
                      />
                    </Draggable>
                  ) : (
                    <div className="relative -top-5 z-0 h-[72px] w-40" />
                  )}
                  {currentCard !== "eat" ? (
                    <Draggable id="eat" isDraggable={isWaitingPhase}>
                      <img
                        src={eatFront}
                        alt="eatFront"
                        className="relative -top-10 z-30 w-[186px]"
                      />
                    </Draggable>
                  ) : (
                    <div className="relative -top-10 z-0 h-[84px] w-40" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </DndContext>
      </div>

      <audio
        src={tacAudioData.eat.frontAudio.audio}
        ref={eatFRef}
        onEnded={handleAudioFrontEnded}
      />
      <audio
        src={tacAudioData.eat.backAudio[0].audio}
        ref={eatB0Ref}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={tacAudioData.eat.backAudio[1].audio}
        ref={eatB1Ref}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={tacAudioData.play.frontAudio.audio}
        ref={playFRef}
        onEnded={handleAudioFrontEnded}
      />
      <audio
        src={tacAudioData.play.backAudio[0].audio}
        ref={playB0Ref}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={tacAudioData.play.backAudio[1].audio}
        ref={playB1Ref}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={tacAudioData.picnic.frontAudio.audio}
        ref={picnicFRef}
        onEnded={handleAudioFrontEnded}
      />
      <audio
        src={tacAudioData.picnic.backAudio[0].audio}
        ref={picnicB0Ref}
        onEnded={handleAudioBackEnded}
      />
      <audio
        src={tacAudioData.picnic.backAudio[1].audio}
        ref={picnicB1Ref}
        onEnded={handleAudioBackEnded}
      />
    </>
  );
}
