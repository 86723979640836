import { MenuItemType } from "../types";

export const appBgColorMapping: {
  [key in MenuItemType]: React.ReactNode;
} = {
  event: "bg-pink1",
  listen: "bg-white1",
  watch: "bg-pink1",
  playing: "bg-gradient-to-b from-pink1 to-pink2",
};
