import { X } from "lucide-react";

import background from "../../assets/images/bookDemo/background.png";
import SlideInAnimation from "../../components/animation/SlideInAnimation";
import Touchable from "../../components/ui/Touchable";
import useStore from "../../hooks/store/useStore";

import ThanksModel from "./components/ThanksModel";
import BookDemoHK from "./BookDemoHK";
import BookDemoOthers from "./BookDemoOthers";

export default function BookDemoDrawer() {
  const { source } = useStore((state) => state.global);
  const { userInformation } = useStore((state) => state.user);
  const { showBookDemoDrawer, setShowBookDemoDrawer, showBookDemoThanks } =
    useStore((state) => state.bookDemo);

  const { trialUserSegmentation: seg, market } = userInformation || {};

  if (source === "clubApp" && (seg === "Decision" || seg === "Action"))
    return null;

  return (
    <>
      <SlideInAnimation
        underlay
        isVisible={showBookDemoDrawer}
        className="fixed bottom-0 left-0 h-fit w-full rounded-t-3xl bg-pink1 pt-3"
      >
        <img
          src={background}
          alt="background"
          className="absolute left-0 top-8 w-full"
        />

        <div className="relative z-10">
          <div className="flex h-11 w-full flex-row-reverse">
            <Touchable onClick={() => setShowBookDemoDrawer(false)}>
              <X className="mx-4 my-2.5 size-6" />
            </Touchable>
          </div>

          {market === "HK" ? <BookDemoHK /> : <BookDemoOthers />}
        </div>
      </SlideInAnimation>

      {showBookDemoThanks ? <ThanksModel /> : null}
    </>
  );
}
