import appDownloadModel from "./appDownloadModel.json";
import bookDemo from "./bookDemo.json";
import common from "./common.json";
import gameMagicPen from "./gameMagicPen.json";
import gameTalkAlongCards from "./gameTalkAlongCards.json";
import gameQACards from "./gameQACards.json";
import menu from "./menu.json";
import pageEvent from "./pageEvent.json";
import pageListen from "./pageListen.json";
import pageWatch from "./pageWatch.json";
import pagePlaying from "./pagePlaying.json";
import webLogin from "./webLogin.json";

const zhHK = {
  ...appDownloadModel,
  ...bookDemo,
  ...common,
  ...gameMagicPen,
  ...gameTalkAlongCards,
  ...gameQACards,
  ...menu,
  ...pageEvent,
  ...pageListen,
  ...pageWatch,
  ...pagePlaying,
  ...webLogin,
};

export default zhHK;
