import { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Loader from "./components/ui/Loader";
import useStore from "./hooks/store/useStore";
import GameLayout from "./layout/game/GameLayout";
import HomeLayout from "./layout/home/HomeLayout";
import changeLanguageByMarket from "./locales/utils/changeLanguageByMarket";
import WebLogin from "./pages/webLogin/WebLogin";
import getWebLoginTokenFromStorage from "./pages/webLogin/utils/getWebLoginTokenFromStorage";
import useExchangeToken from "./services/useExchangeToken";
import useQueryCustom from "./services/utils/useQueryCustom";
import getFontFamily from "./styles/utils/getFontFamily";
import importFont from "./styles/utils/importFont";
import getTokenId from "./utils/getTokenId";
import { MarketType, MenuItemType } from "./types";
import navigateToLeadPage from "./pages/webLogin/utils/navigateToLeadPage";
import useOneTimeToken from "./services/useOneTimeToken";

export default function App() {
  const [showDisneyMenu, setShowDisneyMenu] = useState<boolean | null>(null);
  const [selectedMenuItem, setSelectedMenuItem] =
    useState<MenuItemType>("event");
  const [fontLoaded, setFontLoaded] = useState(false);

  const { source, setSource, language, setLanguage } = useStore(
    (state) => state.global,
  );
  const { accessKey, jwtToken, setTokens } = useStore((state) => state.tokens);
  const { userInformation, setUserInformation } = useStore(
    (state) => state.user,
  );

  const mutation = useExchangeToken();
  const { data } = useQueryCustom(
    "userInformation",
    "user-information",
    Boolean(accessKey && jwtToken),
  );
  const mutationForOneTimeToken = useOneTimeToken();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const sourceData =
      searchParams.get("source") === "clubApp" ? "clubApp" : "web";

    if (sourceData === "clubApp") {
      mutation.mutate(getTokenId());
    } else {
      const data = getWebLoginTokenFromStorage();

      if (data)
        setTokens({ accessKey: data.accessKey, jwtToken: data.jwtToken });
    }

    setSource(sourceData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) setUserInformation(data);
  }, [data, setUserInformation]);

  useEffect(() => {
    if (!userInformation) return;

    const { market, trialUserSegmentation: seg, deviceId } = userInformation;

    if (source === "web" && deviceId === "WEB" && seg === "Awareness") {
      const navigateToLeadPageWithToken = async () => {
        const { tokenId } = await mutationForOneTimeToken.mutateAsync();

        navigateToLeadPage(market, tokenId);
      };

      navigateToLeadPageWithToken();

      return;
    }

    const shouldShowDisneyMenu =
      source === "web" ||
      ((market === "TW" || market === "HK") && seg !== "Action");

    if (seg === "Action") setSelectedMenuItem("listen");

    changeLanguageByMarket(market, setLanguage);
    setShowDisneyMenu(shouldShowDisneyMenu);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInformation, setLanguage, setShowDisneyMenu]);

  useEffect(() => {
    if (!language) return;

    importFont(language, setFontLoaded);
  }, [language]);

  const searchParams = new URLSearchParams(window.location.search);
  const marketParam = searchParams.get("market");
  const isValidMarketParam =
    marketParam && ["HK", "KR", "TW"].includes(marketParam as MarketType);

  if (
    source === "web" &&
    (!isValidMarketParam ||
      marketParam !== "TW" ||
      (userInformation && userInformation.market !== "TW"))
  )
    return <div>This page is not available in your region</div>;

  if (source === "web" && !Boolean(accessKey && jwtToken)) return <WebLogin />;

  if (mutation.isError)
    return (
      <div>An error occurred, please retry or contact the administrator</div>
    );

  if (!language || showDisneyMenu === null || !fontLoaded) return <Loader />;

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <HomeLayout
          selectedMenuItem={selectedMenuItem}
          setSelectedMenuItem={setSelectedMenuItem}
          showDisneyMenu={showDisneyMenu}
          setShowDisneyMenu={setShowDisneyMenu}
        />
      ),
      errorElement: <div>Page Not Found</div>,
    },
    {
      path: "game",
      element: <GameLayout />,
    },
  ]);

  return (
    <div style={{ fontFamily: getFontFamily(language) }}>
      <RouterProvider router={router} />
    </div>
  );
}
