import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Autoplay from "embla-carousel-autoplay";

import {
  type CarouselApi,
  Carousel,
  CarouselContent,
} from "../../components/shadcn/carousel";
import Loader from "../../components/ui/Loader";
import Text from "../../components/ui/Text";
import Touchable from "../../components/ui/Touchable";
import useStore from "../../hooks/store/useStore";
import useCtaStore from "../../hooks/store/useCtaStore";
import useQueryCustom from "../../services/utils/useQueryCustom";
import cn from "../../utils/cn";
import BookButton from "../bookDemo/BookButton";
import { PaddingForBookButtonWeb } from "../bookDemo/constants";

import BenefitsDrawer from "./components/BenefitsDrawer";
import CarouselDots from "./components/CarouselDots";
import FeaturedEventCard from "./components/FeaturedEventCard";
import FeaturedEventVideo from "./components/FeaturedEventVideo";
import RegularEventCard from "./components/RegularEventCard";
import { FeaturedEventDataType, RegularEventDataType } from "./types";

export default function Event() {
  const { t } = useTranslation();

  const [api, setApi] = useState<CarouselApi>();
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [carouselLength, setCarouselLength] = useState(0);
  const [showBenefitsDrawer, setShowBenefitsDrawer] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(-1);

  const { userInformation } = useStore((state) => state.user);
  const { setCtaIntervalBlock } = useCtaStore((state) => state.ctaInterval);
  const { source } = useStore((state) => state.global);

  const { market } = userInformation || {};
  const plugin = useRef(Autoplay({ delay: 5000 }));

  const {
    data: featuredEventsData,
    isLoading: loadingF,
    isError: errorF,
  } = useQueryCustom("useFeaturedEvents", "marketing-featured-events");
  const {
    data: regularEventsData,
    isLoading: loadingR,
    isError: errorR,
  } = useQueryCustom("useRegularEvents", "marketing-regular-events");

  useEffect(() => {
    if (!api) return;

    setCarouselLength(api.scrollSnapList().length);
    setCarouselIndex(api.selectedScrollSnap());

    api.on("select", () => setCarouselIndex(api.selectedScrollSnap()));
  }, [api]);

  if (loadingF || loadingR) return <Loader page="event" />;
  if (errorF || errorR) return <p>Error</p>;

  const touchAction = showBenefitsDrawer ? "touch-none" : "";
  const paddingBottom = source === "web" ? PaddingForBookButtonWeb : "";

  return (
    <div
      className={cn(
        "flex flex-1 flex-col gap-4 p-5",
        paddingBottom,
        touchAction,
      )}
    >
      {featuredEventsData.length > 0 ? (
        <>
          <Carousel
            setApi={setApi}
            // @ts-ignore
            plugins={[plugin.current]}
          >
            <CarouselContent>
              {featuredEventsData.map(
                (data: FeaturedEventDataType, index: number) => (
                  <FeaturedEventCard
                    key={index}
                    index={index}
                    data={data}
                    setCurrentVideoIndex={setCurrentVideoIndex}
                  />
                ),
              )}
            </CarouselContent>

            <CarouselDots
              carouselLength={carouselLength}
              carouselIndex={carouselIndex}
            />
          </Carousel>

          {featuredEventsData.map(
            (data: FeaturedEventDataType, index: number) => (
              <FeaturedEventVideo
                key={index}
                data={data}
                index={index}
                currentVideoIndex={currentVideoIndex}
                setCurrentVideoIndex={setCurrentVideoIndex}
              />
            ),
          )}
        </>
      ) : null}

      {regularEventsData.map((data: RegularEventDataType, index: number) => {
        return <RegularEventCard key={index} data={data} />;
      })}

      {market !== "TW" ? (
        <>
          <div className="relative min-h-7 flex-1">
            <Touchable
              className="absolute bottom-0 left-0 w-full"
              onClick={() => {
                setShowBenefitsDrawer(true);
                setCtaIntervalBlock(true);
              }}
            >
              <Text normal className="text-center text-black6 underline">
                {t("membershipBenefits")}
              </Text>
            </Touchable>
          </div>

          <BenefitsDrawer
            showDrawer={showBenefitsDrawer}
            onClose={() => {
              setShowBenefitsDrawer(false);
              setCtaIntervalBlock(false);
            }}
          />
        </>
      ) : null}

      <BookButton sourcePage="Event" />
    </div>
  );
}
