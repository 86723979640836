import { Dispatch, SetStateAction, useState } from "react";

import { Skeleton } from "../../../components/shadcn/skeleton";
import Image from "../../../components/ui/Image";
import Text from "../../../components/ui/Text";
import Touchable from "../../../components/ui/Touchable";
import useCtaStore from "../../../hooks/store/useCtaStore";
import useStore from "../../../hooks/store/useStore";
import useEventTracking from "../../../services/EventTracking/useEventTracking";
import cn from "../../../utils/cn";
import ctaHandler3 from "../../../utils/ctaHandlers/ctaHandler3";
import ctaHandler4 from "../../../utils/ctaHandlers/ctaHandler4";
import { VideoDataType, VideoListType } from "../types";
import VimeoVideo from "./VimeoVideo";

export default function VideoCard({
  video,
  index,
  icon,
  listType,
  setCurrentVideo,
}: {
  video: VideoDataType;
  index: number;
  icon: string;
  listType: VideoListType;
  setCurrentVideo: Dispatch<SetStateAction<VideoDataType | null>>;
}) {
  const { videoTitle, locked, thumbnailUrl, videoUrl } = video;

  const { userInformation } = useStore((state) => state.user);
  const { setShowingCtaETId } = useCtaStore((state) => state.cta);
  const {
    unlockedVideosCounts,
    setUnlockedVideosCounts,
    lockedVideosCount,
    setLockedVideosCount,
  } = useCtaStore((state) => state.ctaCount);

  const [loaded, setLoaded] = useState(false);
  const [showVideo, setShowVideo] = useState<boolean>(false);

  const mutation = useEventTracking();

  const wrapperStyle = loaded ? "" : "min-h-[95px]";

  const handleImageLoad = () => setLoaded(true);

  const handleClick = () => {
    mutation.mutate({
      pageId: "Watch",
      eventId: videoTitle,
    });

    if (locked) {
      const isCtaTriggered = ctaHandler4(
        userInformation,
        lockedVideosCount,
        setLockedVideosCount,
        setShowingCtaETId,
      );

      if (isCtaTriggered) return;

      setCurrentVideo(video);
    } else {
      const isCtaTriggered = ctaHandler3(
        userInformation,
        listType,
        index,
        unlockedVideosCounts,
        setUnlockedVideosCounts,
        setShowingCtaETId,
      );

      if (isCtaTriggered) return;

      setShowVideo(true);
    }
  };

  return (
    <>
      <Touchable className="w-full" onClick={handleClick}>
        <div
          className={cn(
            wrapperStyle,
            "relative w-full overflow-hidden rounded-lg",
          )}
        >
          <div className="w-full">
            <Image
              src={thumbnailUrl}
              alt={videoTitle}
              className="w-full"
              onLoad={handleImageLoad}
            />

            {!loaded ? (
              <Skeleton className="absolute left-0 top-0 z-20 h-[95px] w-full bg-grey025" />
            ) : null}
          </div>

          <img
            src={icon}
            alt="icon"
            className="absolute bottom-1 right-1 z-[2] size-[18px]"
          />

          {locked ? (
            <div className="absolute left-0 top-0 z-[1] h-full w-full bg-black/30" />
          ) : null}
        </div>

        {loaded ? (
          <div className="mx-1 mb-3 mt-2">
            <Text normal>{videoTitle}</Text>
          </div>
        ) : (
          <Skeleton className="mb-3 mt-2 h-6 w-full bg-grey025" />
        )}
      </Touchable>

      {showVideo ? (
        <VimeoVideo
          videoTitle={videoTitle}
          videoUrl={videoUrl}
          setShowVideo={setShowVideo}
        />
      ) : null}
    </>
  );
}
