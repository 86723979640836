import cn from "../../utils/cn";

function wrapEnglishText(text: string) {
  const pattern = /([a-zA-Z0-9'.]+)/g;
  const matches = text.match(pattern);

  if (!matches) return;

  const parts = [];
  let lastIndex = 0;

  matches.forEach((word, index) => {
    const startIndex = text.indexOf(word, lastIndex);

    if (startIndex > lastIndex) {
      const nonEnglishPart = text.substring(lastIndex, startIndex);

      parts.push(nonEnglishPart);
    }

    parts.push(
      <span key={index} className="font-lexend">
        {word}
      </span>,
    );

    lastIndex = startIndex + word.length;
  });

  if (lastIndex < text.length) {
    const lastNonEnglishPart = text.substring(lastIndex);

    parts.push(lastNonEnglishPart);
  }

  return parts;
}

export default function Text({
  children,
  className,
  style,
  lg,
  normal,
}: {
  children: string;
  className?: string;
  style?: React.CSSProperties;
  lg?: boolean;
  normal?: boolean;
}) {
  const wrapperedText = wrapEnglishText(children);

  return (
    <p
      className={cn(
        "leading-5 text-black8",
        lg ? "text-[17px]" : "text-[15px]",
        normal ? "font-normal" : "font-semibold",
        className,
      )}
      style={style}
    >
      {wrapperedText || children}
    </p>
  );
}
