import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import bubble from "../../assets/images/menu/disney/bubble-xl.webp";
import bubbleWeb from "../../assets/images/menu/disneyWeb/bubble-xl.svg";
import Text from "../../components/ui/Text";
import Touchable from "../../components/ui/Touchable";
import { MenuTypeMapping } from "../../services/EventTracking/mapping";
import useEventTracking from "../../services/EventTracking/useEventTracking";
import cn from "../../utils/cn";
import { MenuItemType } from "../../types";
import menuItemData from "./constants";

export default function MenuItem({
  name,
  className,
  setSelectedMenuItem,
  setShowDisneyMenu,
  isPink,
}: {
  name: string;
  className?: string;
  setSelectedMenuItem: Dispatch<SetStateAction<MenuItemType>>;
  setShowDisneyMenu: Dispatch<SetStateAction<boolean | null>>;
  isPink?: boolean;
}) {
  const { t } = useTranslation();

  const mutation = useEventTracking();

  const { type, image, icon, delay, isRight } = menuItemData[name];
  const wrapperStyle = isRight ? "absolute right-0 top-[100px] z-[1]" : "";
  const bubbleImage = isPink ? bubbleWeb : bubble;

  const handleMenuClick = () => {
    mutation.mutate({
      pageId: "Disney Menu",
      eventId: MenuTypeMapping[type],
    });

    setSelectedMenuItem(type);
    setShowDisneyMenu(false);
  };

  return (
    <div
      className={cn(
        wrapperStyle,
        "disney-menu-slide-in flex size-40 cursor-pointer flex-col items-center bg-cover pt-2",
        className,
      )}
      style={{ backgroundImage: `url(${bubbleImage})`, animationDelay: delay }}
      onClick={handleMenuClick}
    >
      <Touchable>
        <img src={image} alt="disney character" className="size-24" />

        <div className="flex items-center justify-center gap-1">
          <Text lg className="font-medium">
            {t(type)}
          </Text>

          {icon ? <img src={icon} alt="icon" className="size-4" /> : null}
        </div>
      </Touchable>
    </div>
  );
}
