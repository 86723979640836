import { Dispatch, SetStateAction } from "react";

import repeatIcon from "../../../assets/images/listen/repeat.png";
import repeatActiveIcon from "../../../assets/images/listen/repeat-active.png";
import repeatDisabledIcon from "../../../assets/images/listen/repeat-disabled.png";
import Touchable from "../../../components/ui/Touchable";
import useCtaStore from "../../../hooks/store/useCtaStore";
import useStore from "../../../hooks/store/useStore";
import useEventTracking from "../../../services/EventTracking/useEventTracking";

export default function RepeatButton({
  repeat,
  setRepeat,
  isPlaying,
  setIsPlaying,
}: {
  repeat: boolean;
  setRepeat: Dispatch<SetStateAction<boolean>>;
  isPlaying: boolean;
  setIsPlaying: Dispatch<SetStateAction<boolean>>;
}) {
  const { source } = useStore((state) => state.global);
  const { setCtaIntervalBlock } = useCtaStore((state) => state.ctaInterval);
  const { setShowWannaUnlockModel } = useStore((state) => state.bookDemo);
  const { setShowAppDownloadModel } = useStore((state) => state.webVersion);
  const { userInformation } = useStore((state) => state.user);
  const { trialUserSegmentation: seg } = userInformation || {};

  const mutation = useEventTracking();

  const handleEventTracking = () => {
    mutation.mutate({
      pageId: "Listen",
      eventId: "repeat",
    });
  };

  return seg === "Awareness" || source === "web" ? (
    <Touchable
      onClick={() => {
        handleEventTracking();

        if (source === "web") {
          setShowAppDownloadModel(true);
        } else {
          setShowWannaUnlockModel(true);
        }

        if (isPlaying) {
          setCtaIntervalBlock(false);
          setIsPlaying(false);
        }
      }}
    >
      <img className="size-11" src={repeatDisabledIcon} alt="repeatDisabled" />
    </Touchable>
  ) : (
    <Touchable
      onClick={() => {
        handleEventTracking();
        setRepeat(!repeat);
      }}
    >
      <img
        className="size-11"
        src={repeat ? repeatActiveIcon : repeatIcon}
        alt="repeat"
      />
    </Touchable>
  );
}
