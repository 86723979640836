import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import background from "../../assets/images//menu/disney/background.png";
import bubble from "../../assets/images/shared/bubble.png";
import bubble2 from "../../assets/images/shared/bubble2.png";
import Text from "../../components/ui/Text";
import { MenuItemType } from "../../types";
import BookButton from "../bookDemo/BookButton";
import MenuItem from "./MenuItem";

export default function DisneyMenu({
  setSelectedMenuItem,
  setShowDisneyMenu,
}: {
  setSelectedMenuItem: Dispatch<SetStateAction<MenuItemType>>;
  setShowDisneyMenu: Dispatch<SetStateAction<boolean | null>>;
}) {
  const { t } = useTranslation();

  return (
    <div className="relative flex min-h-screen w-full justify-center bg-pink1">
      <div className="flex min-h-screen w-screen min-w-[320px] max-w-[480px] flex-col bg-pink1">
        <div className="flex h-11 items-center justify-center">
          <Text lg>{t("digitalExperience")}</Text>
        </div>

        <div className="mx-10 mb-6 mt-4">
          <Text className="text-center">{t("disneyDescription")}</Text>
        </div>

        <div
          className="relative flex-1 bg-cover bg-top px-9 pt-12"
          style={{ backgroundImage: `url(${background})` }}
        >
          <div className="relative mb-16 w-full">
            <MenuItem
              name="donald"
              setSelectedMenuItem={setSelectedMenuItem}
              setShowDisneyMenu={setShowDisneyMenu}
            />
            <MenuItem
              name="minnie"
              setSelectedMenuItem={setSelectedMenuItem}
              setShowDisneyMenu={setShowDisneyMenu}
            />
          </div>

          <div className="relative mb-6 h-[260px] w-full">
            <MenuItem
              name="mickey"
              setSelectedMenuItem={setSelectedMenuItem}
              setShowDisneyMenu={setShowDisneyMenu}
            />
            <MenuItem
              name="goofy"
              setSelectedMenuItem={setSelectedMenuItem}
              setShowDisneyMenu={setShowDisneyMenu}
            />
          </div>

          <img
            src={bubble}
            alt="bubble"
            className="floating absolute right-4 top-[84px] size-10"
          />
          <img
            src={bubble2}
            alt="bubble2"
            className="floating absolute left-6 top-[248px] size-6"
            style={{ animationDelay: "0.7s" }}
          />
          <img
            src={bubble}
            alt="bubble"
            className="floating absolute right-1.5 top-[484px] size-6"
            style={{ animationDelay: "1.3s" }}
          />
        </div>

        <BookButton sourcePage="Disney Menu" />
      </div>
    </div>
  );
}
